import React, { useEffect, useState } from 'react';
import MetaDecorator from '../src/core/meta/MetaDecorator';
import {
    BlankSection,
} from '../src/components/layouts/sections';
import SimpleCentered from '../src/components/simplecentered';
import axios from 'axios';
import { useRouter } from 'next/router';
import ReactMarkdown from 'react-markdown';

type Banner = {
    data?: { attributes?: { url?: string } };
};

export default function ServiceDetails() {
    const router = useRouter();
    const { slug } = router.query;
    const [pageContent, setPageContent] = useState<any>('');
    const [banner, setBanner] = useState<Banner>();

    useEffect(() => {
        const fetchService = async () => {
            try {
                const result = await axios.get(
                    `${process.env.admin_url}/api/pages?filters[slug][$eq]=${slug}&populate=*`
                );
                setPageContent(result?.data?.data?.[0]);
                setBanner(result?.data?.data?.[0]?.attributes?.PageBanner);
            } catch (e) {
                console.log(e);
            }
        };
        if (slug) {
            fetchService();
        }
    }, [slug, setPageContent, setBanner]);

    return (
        <>
        
                <MetaDecorator
                    title={
                        pageContent?.attributes?.meta_title ||
                        'Virtual gravity | Our Services'
                    }
                    description={
                        pageContent?.attributes?.meta_description ||
                        '"Virtual Gravity is an IT company based in Melbourne, Australia, with a development team in Nepal. We offer high-quality and cost-effective web and mobile application development services to clients worldwide. Contact us today to learn how we can help your business succeed through technology.'
                    }
                    imageUrl="https://virtualgravity.com/images/logo.png"
                    imageAlt="logo"
                />
                {
                    <BlankSection
                        ngClass={
                            'bg-no-repeat bg-white h-full items-center bg-cover ' + `${!banner?.data?.attributes?.url ? 'bg-simple-centered' : ''}`
                        }
                        style={ banner?.data?.attributes?.url ? {
                            backgroundImage:`url(${process.env.admin_url}${banner?.data?.attributes?.url})`
                        } : null }
                    >
                        <SimpleCentered
                            heading1={pageContent?.attributes?.Title}
                            downArrow={false}
                            // headingHighlight={` Grow`}
                            // heading2={` your business`}
                            // description={`We are a design and development company providing services of eCommerce, Mobile Application Development,  UI/UX Design, Web 3.0, NFT &  Blockchain Technology and Web Development `}
                        />
                    </BlankSection>
                }
                <BlankSection
                    ngClass={
                        'relative bg-no-repeat bg-white h-full items-center '
                    }
                >
                    <div className="mt-8 sm:mt-12 lg:mt-16 prose max-w-7xl text-justify">
                        <ReactMarkdown>
                            {pageContent?.attributes?.Content}
                        </ReactMarkdown>
                    </div>
                </BlankSection>
            
        </>
    );
}
