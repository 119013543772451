import React from "react";
import chevronDown from "../../assets/images/chevron-down.svg";
import Image from "next/image";

function SimpleCentered(props: any) {
  return (
    <>
      <div className="flex text-center">
        <div className="flex flex-wrap gap-y-10 text-center px-4 md:max-w-3xl md:mx-auto justify-center">
          <h1 className="text-center text-white">
            {props.heading1} {props.heading2nd}
            <span className="text-orange">{props.headingHighlight}</span>
            {props.heading2}
          </h1>
          {props.description && (
            <p className="text-white">{props.description}</p>
          )}
          {props.children}
        </div>
      </div>
    </>
  );
}

export default SimpleCentered;
